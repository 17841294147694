<template>
  <van-overlay @mousewheel.prevent.native="() => { }" class="high-light-show-wrap" v-if="popupHighLightShow" :show="popupHighLightShow">
    <div class="high-light-show">
      <div @click="() => { SetPopupHighLightShow({ val: false }) }" class="close-btn point">
        <img src="../../assets/images/mall/pc-mall-close.png" alt="">
      </div>
      <index></index>
    </div>
  </van-overlay>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Index from "./index.vue";
export default {
  components: {
    Index
  },
  computed: {
    ...mapState(['popupHighLightShow', 'popupHighLightId'])
  },
  methods: {
    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "Leave",
      "GetRegisterShow",
      "GetSignInShow",
      'SetPopupHighLightShow'
    ]),
  }
}
</script>

<style lang="scss">
.high-light-show-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .high-light-show {
    width: fit-content;
    height: fit-content;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    // background: #fff;
    z-index: 1000;

    .close-btn {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      z-index: 1001;
      opacity: 0.7;
      img {
        width: 0.18rem;
      }
    }
  }
}
</style>